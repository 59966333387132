:local {
  [data-theme="default"] {
    --meeting-type-details-background: #d5dee8;
    --invite-text-color: #1f3049;
  }

  [data-theme="dark"] {
    --meeting-type-details-background: var(--primary-purple);
    --invite-text-color: var(--neutral-white);
  }

  @supports (backdrop-filter: blur(6px)) {
    .meetingTypeDetails {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
      background: var(--meeting-type-details-background);
      backdrop-filter: blur(6px);
    }
  }

  @supports not (backdrop-filter: blur(6px)) {
    .meetingTypeDetails {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
      background: var(--meeting-type-details-background);
    }
  }

  .meetingTypeDetails__invite {
    padding: var(--spacing-x-small) 10%;
  }

  .meetingTypeDetails_inviteTextArea {
    width: 100%;
    height: auto;
    background-color: transparent;
    color: var(--invite-text-color);
    border: none;
    overflow: scroll;
    resize: none;
    font-family: var(--font-family);
    font-size: 0.8em;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 10px;
    white-space: break-spaces;
  }

  .meetingTypeDetails_inviteTextArea--minimum {
    max-height: 0px;
  }

  .meetingTypeDetails_inviteTextArea--full {
    max-height: none;
  }

  .meetingTypeDetails_invite__expand_button {
    border: none;
    background-color: transparent;
    color: var(--invite-text-color);
    font-weight: normal;
  }

  .meetingTypeDetails_invite_expand_button__icon {
    color: var(--invite-text-color);
  }
}
