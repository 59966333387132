:local {
  .grid {
    display: grid;
    height: 100%;
    width: 100%;
  }

  .gridItem {
    height: 100%;
    width: 100%;
  }
}
