:local {
  .meetingLinkProvider {
    max-width: 100%;
    display: flex;
    margin-top: 5px;
    justify-content: flex-start;
  }

  .meetingLinkProvider_title {
    font-size: x-small;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bullets {
    padding-left: 2em;
  }
}
