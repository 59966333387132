@import "../style.module.scss";

:local {
  [data-theme="default"] {
    --meetingTypes-primary-background-color: var(--neutral-200);
    --meetingTypes-secondary-background-color: var(--neutral-white);
    --meetingTypes-button-color: var(--primary-blue);
    --meetingTypes-text-color: var(--neutral-black);
    --meetingTypes-subNavItem-color: var(--neutral-600);
  }

  [data-theme="dark"] {
    --meetingTypes-primary-background-color: var(--primary-purple);
    --meetingTypes-secondary-background-color: var(--secondary-purple);
    --meetingTypes-button-color: var(--neutral-white);
    --meetingTypes-text-color: var(--neutral-white);
    --meetingTypes-subNavItem-color: var(--neutral-white);
  }

  .meetingTemplates {
    @extend .meetingTypes;
  }

  .meetingTemplates__content {
    @extend .meetingTypes__content;
  }

  .meetingTemplates_templateAndList {
    @extend .meetingTypeAndList;
  }
}
