@import url("https://fonts.googleapis.com/css?family=Inter:300,400,600,700&display=swap");
@import "../../node_modules/quill/dist/quill.snow.css";
@import "../../node_modules/nprogress/nprogress.css";
@import "../../node_modules/react-dates/lib/css/_datepicker.css";
@import "../components/baseUnit/vars";
@import "../components/colors/primaries";
@import "../components/colors/neutrals";
@import "../components/colors/text";
@import "../components/colorsV2/vars";
@import "../components/shadows/shadows";
@import "../components/spacing/vars";
@import "../components/theme/theme";
@import "../components/typography/typographyVars";
@import "../components/input/inputVars";
@import "vars";
@import "helpers";
@import "grid";
@import "tooltip";
@import "modal";
@import "slider";
@import "text";
@import "mixins";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

html {
  font-family: var(--kl-font-family);
  height: 100%;
}

// TODO: MATT Create an item to remove this
#kronologic-ai-app {
  height: 100%;
  min-height: 100%;
}

// TODO(Matt): Hopefully we can remove these overrides as we start convering more on the diffrent tools we use
body {
  color: var(--primary-text-color);
  font-size: 16px;
  font-family: var(--kl-font-family);
  font-weight: 400;
}

button {
  font-family: var(--kl-font-family);
}

input {
  font-family: var(--kl-font-family);
}

.react-dropdown-select {
  border-radius: var(--border-radius) !important;
  border: var(--input-border) var(--input-border-style)
    var(--input-border-color) !important;
  background: var(--input-bg-color);
  margin: 0.5rem 0;

  .react-dropdown-select-option {
    border-radius: var(--border-radius) !important;
    background: var(--primary-color);
    align-items: center;
    padding: 0 5px 0 15px;
    color: var(--dd-multi-color);
  }

  .react-dropdown-select-input {
    height: 25px;
    width: auto;

    background: var(--input-bg-color);
    color: var(--input-text-color);
    padding-left: 15px;
  }

  .react-dropdown-select-type-single {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .react-dropdown-select-dropdown {
    font-size: var(--input-size);
    background: var(--input-bg-color);
    color: var(--input-text-color);
    border: var(--input-border) var(--input-border-style)
      var(--input-border-color) !important;
  }

  .react-dropdown-select-item {
    border: none !important;
  }
}

.kl-body {
  background: var(--background-color);
  height: 100%;
  min-height: 100%;
}

.DateRangePicker {
  z-index: 5;
}

.DateRangePickerInput_arrow_svg {
  margin: 0.75rem 0;
}

.DateInput_input {
  background: var(--multi-guest-menu-cell-background-color);
  border-width: 2px 0 0 0;
  border-style: solid;
  border-color: var(--multi-guest-menu-cell-border-color);
  border-radius: 0;
  color: var(--primary-text-color);
  font-family: var(--font-family);
  font-size: 12px;
  height: 40px;
  margin: 0;
  overflow: hidden;
  padding: 0 0 0 15px;
  width: 100%;
}

#endDate {
  border-right: 1px solid var(--multi-guest-menu-cell-border-color);
}

.DateRangePickerInput_arrow {
  background: var(--multi-guest-menu-cell-background-color);
  border-width: 2px 0 0 0;
  border-style: solid;
  border-color: var(--multi-guest-menu-cell-border-color);
  padding: 0;
  height: 40px;
}

.DateRangePickerInput__withBorder {
  border: none !important;
  border-radius: 0;
}

.text-center {
  text-align: center;
}
