:local {
  [data-theme="default"] {
    --editor-text-color: hsl(0, 0%, 30%);
    --editor-bg-color: hsl(0, 0%, 99%);
    --editor-border-color: hsl(0, 0%, 90%);
  }

  [data-theme="dark"] {
    --editor-text-color: hsl(0, 0%, 90%);
    --editor-bg-color: hsl(0, 0%, 20%);
    --editor-border-color: hsl(0, 0%, 10%);
  }

  .quill_wrapper {
    :global {
      .ql-stroke {
        stroke: var(--editor-text-color) !important;
      }

      .ql-editor {
        background-color: var(--input-background-color);
        font-size: var(--font-size-large);
        font-style: var(--font-family);
        height: 400px;
      }

      .ql-container {
        border: none !important;
      }

      .ql-picker-label {
        background-color: var(--input-background-color);
        color: var(--input-font-color);
      }

      .ql-toolbar {
        border: 1px solid var(--meetingTypes-primary-background-color) !important;
        background-color: var(--meetingTypes-primary-background-color);
      }

      .ql-tooltip {
        left: 0 !important;
        top: 0 !important;
      }
    }
  }
}
