:local {
  [data-theme="default"] {
    --all-dms-icon-color: rgba(220, 220, 239, 0.9);
    --greyed-out: #49505a;
    --invert: invert(0);
    --text-color: #000000;
    --calendar-background: #e9e9eb;
    --input-background: #f8f9fb;
  }

  [data-theme="dark"] {
    --all-dms-icon-color: var(--neutral-white);
    --greyed-out: #99a3b0;
    --invert: invert(1);
    --text-color: #ffffff;
    --calendar-background: #424e6f;
    --input-background: #2d3855;
  }

  .meetingTypeDetails__headerContainer {
    position: relative;
  }

  .meetingTypeDetails__startTimeButtonContainer {
    position: absolute;
    right: var(--spacing-large);
  }

  .meetingTypeDetailsHeader {
    position: relative;
    padding: 0 10px;
  }

  .date_time_button {
    color: var(--text-color);
    font-size: x-small;
  }

  .date_time_button--greyed-out {
    color: var(--greyed-out);
    font-size: x-small;
  }

  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    right: 30px;
    top: 50px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 25px solid var(--calendar-background);
  }

  .calendarWindow {
    z-index: 7;
    background: var(--calendar-background);
    position: absolute;
    top: 75px;
    right: 10px;
    font-size: 10px;
    border-radius: 2px;
    padding-bottom: 0px;
    font-family: var(--font-family);

    .cardHeader {
      font-size: 10px;
    }

    .headerSpace {
      margin-bottom: 15px;
    }

    .dateInput {
      width: 100px;
      height: 30px;
      top: 20px;
      background-color: var(--input-background);
      border: none;
      border-radius: 2px;
      text-align: center;
      color: var(--text-color);
      font-size: 10px;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      filter: var(--invert);
    }

    .timeInput {
      width: 100px;
      height: 30px;
      background-color: var(--input-background);
      border: none;
      border-radius: 4px;
      text-align: center;
      color: var(--text-color);
      font-family: var(--font-family);
      padding: 5px;
      font-size: 10px;
    }

    .calendarCancelButton {
      height: 30px;
      width: 70px;
      border: none;
      border-radius: 2px;
      background-color: #c4c4c4;
      color: var(--text-color);
      font-weight: 400;
      font-size: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .calendarSaveButton {
      height: 30px;
      width: 70px;
      border: none;
      border-radius: 2px;
      background-color: #31b4ff;
      color: var(--text-color);
      font-weight: 400;
      font-size: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .warning {
      color: var(--chilean-fire-color);
      font-size: 1.1em;
      font-weight: 500;
      text-align: center;
    }
  }
}
