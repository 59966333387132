@import "../components/input/inputVars";

:local {
  [data-theme="default"] {
    --meetingTypes-primary-background-color: var(--neutral-200);
    --meetingTypes-secondary-background-color: var(--neutral-white);
    --meetingTypes-button-color: var(--primary-blue);
    --meetingTypes-text-color: var(--neutral-black);
    --meetingTypes-subNavItem-color: var(--neutral-600);
    --meetingTypes-listItem-button: black;
  }

  [data-theme="dark"] {
    --meetingTypes-primary-background-color: var(--primary-purple);
    --meetingTypes-secondary-background-color: var(--secondary-purple);
    --meetingTypes-button-color: var(--neutral-white);
    --meetingTypes-text-color: var(--neutral-white);
    --meetingTypes-subNavItem-color: var(--neutral-white);
    --meetingTypes-listItem-button: white;
  }

  .meetingTypes {
    background-color: var(--meetingTypes-primary-background-color);
    grid-template-areas:
      "."
      "content"
      ".";
    grid-template-columns: 1fr;
    grid-template-rows: var(--spacing-large) 1fr var(--spacing-large);
    height: 100%;
    min-height: 700px;
    padding: 0 var(--spacing-large);
  }

  .meetingTypes__listColumn {
    background-color: var(--meetingTypes-secondary-background-color);
    border-radius: 4px;
  }

  .meetingTypes__list {
    overflow: hidden;
    padding: var(--spacing-large) var(--spacing-small);
  }

  .meetingTypes__listItem {
    background-color: var(--meetingTypes-primary-background-color);
    border: none;
    border-radius: 4px;
    color: var(--meetingTypes-text-color);
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: 110px;
    padding: 0 var(--spacing-med);
    width: 100%;
  }

  .meetingTypes__listItem--highlighted {
    border: 1px solid var(--primary-blue);
  }

  .meetingTypes__header {
    background-color: var(--secondary-purple);
    border-radius: 4px;
  }

  .meetingTypes__buttonMenu {
    color: var(--primary-blue);
  }

  .meetingTypes__buttonMenu__disabled {
    cursor: not-allowed;
  }

  .meetingTypes__createdByData {
    margin-left: 20px;
  }

  .meetingTypes__createdByData__item {
    white-space: nowrap;
  }

  .meetingTypes__tabMenu {
    background-color: var(--meetingTypes-primary-background-color);
    min-height: 64px;
  }

  .meetingType {
    background-color: var(--meetingTypes-secondary-background-color);
    border-radius: 4px;
    display: flex;
    height: 100%;
  }

  .meetingType__tab {
    color: var(--neutral-500);
    position: relative;
    white-space: nowrap;
  }

  .meetingType__tabBar {
    background-color: var(--primary-blue);
    bottom: 0px;
    height: 2px;
    position: absolute;
    width: 100%;
  }

  .meetingType__tab--selected {
    color: var(--primary-blue);
  }

  .meetingType__breadcrumb {
    background-color: var(--primary-purple);
    border-radius: 4px;
  }

  .meetingType__dynamicVariables {
    max-height: 180px;
    overflow: scroll;
  }

  .meetingType__dynamicVariables2 {
    overflow: scroll;
  }

  .meetingType__costInput {
    max-width: 400px;
  }

  .meetingType__dynamicVariable {
    background-color: var(--primary-purple);
    border-radius: 4px;
    flex: 1;
    height: 70px;
    margin: 10px;
    min-width: 200px;
    overflow: hidden;
  }

  .meetingType__dynamicVariableText {
    color: var(--primary-blue);
  }

  .meetingType__dynamicVariableSampleText {
    color: var(--text-50-color);
    text-overflow: ellipsis;
  }

  .meetingType__requiredText {
    color: var(--primary-orange);
    margin-left: 2px;
  }

  .meetingType__typeTab {
    // TODO(Matt) Fix this color
    color: white;
    flex: 1;
    height: 100%;
  }

  .meetingType__typeTab--selected {
    background-color: var(--primary-blue);
  }

  .meetingType__textArea {
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    color: var(--meetingTypes-text-color);
    flex: 1;
    font-size: var(--font-size-large);
    font-family: var(--font-family);
    min-height: 260px;
    overflow: scroll;
    // max-height: 520px;
    padding: 5px 12px;
  }

  @mixin meetingType--step {
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .meetingType__stepIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%);
  }

  .meetingType__stepComplete {
    @include meetingType--step;
    background-color: var(--primary-green);
    color: var(--neutral-white);
  }

  .meetingType__stepIncomplete {
    @include meetingType--step;
    background-color: var(--primary-orange);
    color: var(--neutral-white);
  }

  // TODO(Matt): Refactor this better
  @mixin meeting-type-header {
    align-items: center;
    border-radius: 12px;
    display: flex;
    height: 24px;
    justify-content: center;
    min-width: 24px;
  }

  .meetingType__emailButtonSign {
    @include meeting-type-header;
    background-color: var(--primary-blue);
    color: white;
    text-align: center;
  }

  .meetingType__emailButtonTitle {
    @include meeting-type-header;
    color: var(--primary-blue);
  }

  .meetingType__removeEmailIcon {
    color: var(--primary-blue);
  }

  .meetingType__emailButtonIcon {
    color: white;
  }

  .meetingType__stepCount {
    @include meeting-type-header;
    color: var(--neutral-white);
  }

  .meetingType__stepCount--standard {
    background-color: var(--primary-blue);
  }

  .meetingType__stepCount--warn {
    background-color: var(--primary-yellow);
  }

  .meetingType__stepTitle--standard {
    color: var(--primary-blue);
  }

  .meetingType__stepTitle--warn {
    color: var(--primary-yellow);
  }

  .meetingType__invite {
    background-color: var(--meetingTypes-primary-background-color);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    height: 100%;
    min-height: 600px;
  }

  .meetingType__team {
    background-color: var(--meetingTypes-primary-background-color);
    border-radius: 4px;
  }

  .meetingType__defaultUser {
    white-space: nowrap;
  }

  .meetingType__invitePreview {
    background-color: var(--meetingTypes-primary-background-color);
    border-radius: 4px;
    min-height: 100%;
  }

  .meetingType__locatiton {
    color: var(--primary-blue);
  }

  .meetingType__inviteNotesPreview {
    white-space: pre-line;
  }

  .meetingType__saveButton {
    align-self: flex-end;
    justify-self: end;
  }

  @media (min-width: 1780px) {
    .meetingTypeAndList {
      display: grid;
      grid-template-areas: "list buffer meetingType";
      grid-template-columns: 400px var(--spacing-large) 1fr;
      grid-template-rows: 1fr;
    }
  }

  @media (max-width: 1779px) {
    .meetingTypeAndList {
      display: grid;
      grid-template-areas: "meetingType";
      grid-template-columns: minmax(var(--screen-xx-large), 1fr);
      grid-template-rows: 1fr;
    }
  }

  // TODO(Matt): Add back the box shadows
  .meetingTypes__content {
    overflow: hidden;
  }

  .meetingTypes__listGridItem {
    overflow: hidden;
  }

  .meetingTypes__meetingTypeGridItem {
    overflow: hidden;
  }

  .meetingType__grid {
    background-color: var(--meetingTypes-secondary-background-color);
    grid-template-areas: ". nav . content1 contentSpacing content2 .";
    grid-template-columns:
      var(--spacing-jumbo) 150px var(--spacing-large) minmax(450px, 3fr)
      var(--spacing-jumbo)
      minmax(450px, 2fr)
      var(--spacing-jumbo);
    grid-template-rows: minmax(0, 1fr);
    overflow: hidden;
  }

  .meetingType__inviteInput {
    background-color: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    color: var(--meetingTypes-text-color);
    border-radius: 4px;
    display: flex; // TODO(Matt): Hides white space... should we just not allow any whitespace character besides /s
    font-size: var(--font-size-large);
    min-height: 40px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding: 8px 12px;
  }

  .meetingType__contentGridItem {
    max-height: 100%;
  }

  .meetingType__dynamicVariable2 {
    background-color: var(--meetingTypes-primary-background-color);
    border-radius: 4px;
    height: 70px;
    margin: 10px 0px;
    overflow: hidden;
    text-align: left;
    padding-left: var(--spacing-large);
    width: 50%;
  }

  .meetingType__dynamicVariable2:hover {
    border: 1px solid var(--primary-blue);
  }

  .meetingType__progressBar {
    height: 12px;
    width: 100%;
  }

  .meetingType__progress {
    background-color: var(--primary-green);
    border-radius: 14px;
    height: 100%;
    width: 14%;
  }

  .meetingType__navItem {
    display: flex;
  }

  .meetingType__navItemLink {
    color: var(--meetingTypes-subNavItem-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-blue);
    }
  }

  .meetingType__navItemLink--current {
    color: var(--primary-blue);
  }

  .meetingType__progressInfoRow {
    border-top: 1px solid white;
  }

  .meetingType__accordionButton {
    color: var(--meetingTypes-subNavItem-color);
  }

  .meetingType__slider {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-small);
  }

  .meetingType__emptyView {
    background-color: var(--meetingTypes-primary-background-color);
    flex: 1;
  }

  .meetingType__emptyViewLoading {
    color: white;
  }

  .meetingType__teamListItem--highlighted {
    border: 1px solid var(--primary-blue);
  }

  .meetingType__teamListItem {
    background-color: var(--meetingTypes-primary-background-color);
  }

  .meetingType__teamListItem_email {
    display: inline;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meetingType__teamListItemIcon {
    color: white;
  }

  .meetingType__teamListItemIcon--blue {
    color: var(--primary-blue);
  }

  .meetingType__orderButton {
    padding: var(--spacing-tiny) var(--spacing-small);
  }

  .meetingType__orderButtonIcon {
    color: var(--primary-blue);
  }

  .meetingType__distributionMeeting {
    fill: var(--meetingTypes-secondary-background-color);
    stroke: var(--meetingTypes-text-color);
  }

  .meetingType__distributionPreview {
    background-color: var(--meetingTypes-primary-background-color);
    height: 100%;
  }

  .meetingType__distributionPreviewLine {
    border-top: 1px solid white;
    flex: 0;
    width: 100%;
  }

  .meetingType__distributionPreviewText {
    // TODO: Make this a particular font-size
    font-size: 20px;
    fill: var(--meetingTypes-text-color);
  }

  .meetingType__distributionExplantionText {
    color: var(--meetingTypes-text-color);
  }

  .meetingType__monetizationRow {
    margin-left: var(--spacing-xx-large);
  }

  .meetingType__monetizationColumn:first-child {
    max-width: 150px;
  }

  .meetingType__monetizationColumn {
    flex: 1;
    position: relative;
    max-width: 175px;
    margin: 10px 0;
  }

  .meetingType__monetizationText {
    position: absolute;
    transform: translate(0, -100%);
    width: 100%;
  }

  .meetingType__listErrorText {
    text-align: center;
  }

  .meetingType__userRoutingCloseButton {
    color: var(--meetingTypes-subNavItem-color);
  }

  .meetingType__dropzone {
    background-color: var(--meetingTypes-primary-background-color);
  }

  .meetingType__stepNavListItemContainer {
    position: absolute;
    transform: translateX(-50%);
  }

  .meetingType__showMenuButton {
    color: var(--meetingTypes-text-color);
  }

  .meetingType__listItemButton {
    color: var(--meetingTypes-listItem-button);
  }

  .meetingType__drawerContainer {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .meetingType__drawerOverlay {
    flex: 1;
    height: 100%;
  }

  .meetingType__drawer {
    box-shadow: var(--shadow-medium);
    height: 100%;
    width: 400px;
  }

  .meetingType__drawer--slidein {
    animation-duration: 0.5s;
    animation-name: slidein;
    transform: translateX(0%);
  }

  .meetingType__drawer--slideout {
    animation-duration: 0.5s;
    animation-name: slideout;
    transform: translateX(-100%);
  }

  .meetingTypes__listItemName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .meetingType__stepCompletion {
    height: 24px;
    width: 24px;
  }

  .meetingType__stepCompletionProgressRing {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .meetingType__cloudIcon--disabled {
    color: var(--input-disabled-color);
  }

  .meetingType__messengerPreview {
    border: 2px solid var(--meetingTypes-primary-background-color);
    padding-bottom: var(--spacing-small);
    position: relative;
  }

  .messenger_options {
    bottom: var(--spacing-small);
    position: absolute;
  }

  .messenger_option {
    background-color: var(--meetingTypes-primary-background-color);
    border: 1px solid white;
    border-radius: 24px;
    color: var(--text-color);
    padding: var(--spacing-x-small);
    margin: 0 var(--spacing-x-small);
  }

  .meetingType__addDynamicVariable {
    background-color: var(--meetingTypes-primary-background-color);
    border-radius: 4px;
    padding: 0 var(--spacing-med);
  }

  .meetingType__uploadCsvButton {
    white-space: nowrap;
  }

  .meetingTypes__k_logo {
    height: 27px;
  }

  .reminder_rules {
    margin-left: 50px;
  }

  .unsubscribeInfo {
    font-size: 12px;
  }

  .unsubscribeHelperText {
    margin-top: 5px;
    margin-left: 50px;
    font-size: 12px;
  }

  .linkText {
    color: var(--summer-sky-color);
    text-decoration: underline;
  }

  @keyframes slidein {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideout {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .chip {
    margin-left: 5px;
  }

  .chipContainer {
    display: flex;
    align-items: center;
  }
}
