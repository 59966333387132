/* Basic editor styles */
.ProseMirror {
  min-height: 300px;

  > * + * {
    margin-top: 0.75em;
  }
  &.preview {
    div {
      margin-top: 0;
    }
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.tiptap-wrapper {
  outline: 1px solid #63707c;
  border-radius: 5px;
  padding: 10px;
  &:focus-within {
    outline: 2px solid #2baef9;
  }
}

.tiptap-menu {
  padding-bottom: 10px;
  border-bottom: 1px solid #63707c;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}

.tiptap-menu-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tiptap-menu-button {
  font-size: 20px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  svg {
    display: block;
  }
  &:disabled {
    pointer-events: none;
  }
  &:hover {
    color: #2baef9;
  }
  &.is-active {
    color: #2baef9;
  }
}

.tiptap-editor {
  padding: 10px 0;

  .kronologic-tiptap-editor--hidden {
    display: none;
  }
}
