:local {
  .button {
    border-radius: 50%;
    cursor: pointer;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button--inactive {
    background: #ffffff;
    border: 1px solid #a8b1bd;
  }

  .button--inactive:hover {
    border: 1px solid #2baef9;
  }

  .button--active {
    background: #ffffff;
    border: 1px solid #2baef9;
  }

  .button--disabled-inactive {
    background: var(--neutral-300);
  }

  .button--standard {
    height: 16px;
    width: 16px;
  }

  .button--innerCircle--standard {
    height: 8px;
    width: 8px;
  }

  .button__activeInnerCircle {
    background: #2baef9;
    border-radius: 50%;
  }

  .button__disabledInnerCircle {
    background: var(--neutral-500);
    border-radius: 50%;
  }
}
